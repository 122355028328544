body > #root > div {
  height: 100vh;
}
@import url('https://fonts.googleapis.com/css2?family=Six+Caps&display=swap');
body {
    margin: 0;
    font-family: 'Six Caps', sans-serif;
     background-color: black;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: black;
  color: white;
  justify-content: space-between;
}
.header h1{
  margin-left: 20px;
  color: #f9d71c;
}
.header .link{
  display: flex;
  
}
.header .link div{
  margin-right: 30px;
}
.skeleton{
  color: #f9d71c;
  text-align: center;
}
.skeleton h1{
font-size: 300px;
}
.skeleton h2{
font-size: 150px;
}
.skeleton hr{
  margin-top: -230px;
  margin-bottom: -130px;
  height: 25px;
  background-color: #fd48fe;
  border: #fd48fe;
}
.App {
 
  display: flex;
  flex-direction: column;
align-items: center;
  background-color: black;

  
}
.skeletonApp{
  display: flex;
  align-items: center;
  
  height: 100vh;
  
}